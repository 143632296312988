<template>
  <div>
    <c-btn 
      v-if="editable&&!disabled"
      :disabled="!isExistsTask"
      flat
      label="상세업무" 
      icon="info_outline"
      @btnClicked="openRalationTablePop">
    </c-btn>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'imprRelationTag',
  props: {
    taskParam: {
      type: Object,
      default() {
        return {
          sopImprovementId: null,
          ibmTaskTypeCd: null,
          ibmTaskUnderTypeCd: null,
          relationTableKey: null,
        }
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      improveRelationTask: {
        sopImprovementId: null,
        ibmTaskTypeCd: null,
        ibmTaskUnderTypeCd: null,
        relationTableKey: null,
        relationTableTitle: '연결된 업무가 없습니다..',
        relationTableNavi: '',
        relationTableContents: '',
      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        escDismiss: false,
        backdropDismiss: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'taskParam.sopImprovementId': {
      handler: function () {
        this.getDetail();
      },
      deep: true,
    },
  },
  computed: {
    isExistsTask() {
      return Boolean(this.improveRelationTask.sopImprovementId);
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.ibm.improve.relationTask.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.taskParam.sopImprovementId) {
        this.$http.url = this.$format(this.detailUrl, this.taskParam.sopImprovementId);
        this.$http.type = 'GET';
        this.$http.param = {
          ibmTaskTypeCd: this.taskParam.ibmTaskTypeCd,
          ibmTaskUnderTypeCd: this.taskParam.ibmTaskUnderTypeCd,
        };
        this.$http.request((_result) => {
          this.$_.extend(this.improveRelationTask, _result.data);
        },);
      } else {
        // nothing...
      }
    },
    openRalationTablePop() {
      // this.popupOptions.title = this.improveRelationTask.relationTableTitle;
      this.popupOptions.target = () => import(`${'./imprRelationTagPop.vue'}`);
      this.popupOptions.param = this.improveRelationTask;
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.escDismiss = true;
      this.popupOptions.backdropDismiss = true;
      this.popupOptions.closeCallback = this.closeRalationTablePopup;
    },
    closeRalationTablePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>